import { Viewer, Worker } from "@react-pdf-viewer/core";
import React from "react";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";

const Root = () => {
  return (
    <div
      style={{
        border: "1px solid rgba(0, 0, 0, 0.3)",
        width: "750px",
        height: "750px",
        overflow: "scroll"
      }}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer fileUrl="https://acrobatservices.adobe.com/view-sdk-demo/PDFs/Bodea%20Brochure.pdf" />
      </Worker>
    </div>
  );
};

export default Root;
